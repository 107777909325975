
$(document).ready(function() {
    $("#recontacter_submit").on("click", function() {
            telephone = $("#recontacter_telephone").val();
            telephone = telephone.split(' ').join('');
            telephone = telephone.split('.').join('');
            // telephone = telephone.replaceAll(" ", "").replaceAll(".", "");
            // console.log(telephone);
            if (telephone.length == 10 && telephone.match("[0-9]")) {
                $.ajax({
                    method: "POST",
                    url: $('#get_host').html() + "query/demande_rappel",
                    data: {
                        'telephone': telephone
                    }
                }).done(function() {
                    $("#success_tel").show();
                    window.setTimeout(function() {
                        $("#success_tel").fadeTo(8000, 1).slideUp(500, function() {
                            $(this).hide();
                            $("#recontacter_telephone").val("");
                        });
                    });
                    return false;
                })
            } else {
                $("#fail_tel").show();
                window.setTimeout(function() {
                    $("#fail_tel").fadeTo(8000, 1).slideUp(500, function() {
                        $(this).hide();
                    });
                });
                return false;
            }
        });
    return false;
});