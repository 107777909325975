// $(document).ready(function() {
// 	$('#form_date').on('click', function() {
// 		$('.horaires-modal').modal('show');
// 	});



// 	$('.essai-day').on('click', function() {
// 		if (!$(this).hasClass('disabled')) {
// 			$('.essai-day, .essai-hour').each( function () {
// 				$(this).removeClass('selected');
// 			});
// 			selectedDayData = $(this).data('day');
// 			selectedDayHours = horairesDisponibles[selectedDayData];
// 			$('.essai-hour').each( function () {
// 				$(this).addClass('disabled');
// 				if (selectedDayHours[$(this).data('hour')] == true) {
// 					$(this).removeClass('disabled');
// 				};
// 			});
// 			$(this).addClass('selected');
// 		}
// 	});

// 	$('.essai-hour').on('click', function() {
// 		if (!$(this).hasClass('disabled')) {
// 			$('.essai-hour').each( function () {
// 				$(this).removeClass('selected');
// 			});
// 			$(this).addClass('selected');
// 			$('.essai-horaire-validation').removeClass('disabled');
// 		}
// 	});

// 	$('.essai-horaire-validation').on('click', function() {
// 		if (!$(this).hasClass('disabled')) {
// 			selectedDay = $('.essai-day.selected').text().replace('.','');
// 			selectedHour = $('.essai-hour.selected').data('hour');
// 			$('.horaires-modal').modal('hide');
// 			$('#form_date').val(selectedDay + ' à ' + selectedHour + 'H00');
// 		}
// 	});

// 	$(".readonly").on('keydown paste focus mousedown', function(e){
//         if(e.keyCode != 9) // ignore tab
//             e.preventDefault();
//     });
// });
