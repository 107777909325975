// Basé sur l'éxistant : produit_details.js

// Activation du datetimepicker
$(document).ready(function() {
    $.datetimepicker.setLocale('fr');
    $('#form-privatisation-date').datetimepicker({
        format: 'j F Y',
        lang: 'fr',
        timepicker: false,
        minDate: '+1970/01/09',
        validateOnBlur: false,
        dayOfWeekStart: 1,
        disabledWeekDays: [0, 1, 6],
    });
});

// Contrôle des champs au click sur ENVOYER
$('body').on("click", '#button-submit-privatisation', function () {
    // Si input_valide reste à true, alors tout les champs sont valide
    input_valide = true;
    // Boucle tout les champs
    $("input[name^='privatisation_']").each(function() {
        if ($(this).val() != "") {
            // Si le champ n'est pas vide
            $(this).css("border", '1px dashed #cecac1');
        } else {
            // Si le champ est vide
            $(this).css("border", '1px dashed red');
            input_valide = false;
        }
    });

    // Regex Telephone
    regex = new RegExp(/^\d{10}$/);
    if (!(regex.test($("input[name='privatisation_telephone']").val()))) {
        $("input[name='privatisation_telephone']").css("border", '1px dashed red');
        input_valide = false;
    } else {
        $("input[name='privatisation_telephone']").css("border", '1px dashed #cecac1');
    }

    // Regex Mail
    regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!(regex.test($("input[name='privatisation_email']").val()))) {
        $("input[name='privatisation_email']").css("border", '1px dashed red');
        input_valide = false;
    }	else {
        $("input[name='privatisation_email']").css("border", '1px dashed #cecac1');
    }

    // TODO : CHECK DATE
    if (!($('#form-privatisation-date').datetimepicker('getValue').getTime() >= Date.now())) {
        $("input[name='privatisation_date']").css("border", '1px dashed red');
        input_valide = false;
    } else {
        $("input[name='privatisation_date']").css("border", '1px dashed #cecac1');
    }

    // Si l'un des champs vérifiés n'est pas valide
    if (input_valide == false) {
        return false;
    }

    form = new FormData();
    form.append('privatisation-societe', $("input[name='privatisation_societe']").val());
    form.append('privatisation-telephone', $("input[name='privatisation_telephone']").val());
    form.append('privatisation-nom', $("input[name='privatisation_nom']").val());
    form.append('privatisation-prenom', $("input[name='privatisation_prenom']").val());
    form.append('privatisation-email', $("input[name='privatisation_email']").val());

    let options = {year: "numeric", month: "long", day: "numeric"};
    form.append('privatisation-date', $('#form-privatisation-date').datetimepicker('getValue').toLocaleString('fr-FR', options));

    form.append("privatisation-message", $("textarea[name='privatisation_message']").val());

    $.ajax({
        type: 'POST',
        url: siteURL + "privatisation",
        data: form,
        cache: false,
        contentType: false,
        processData: false,
        success: function(data) {
            if (data.trim() == "ok") {
                $("#button-submit-privatisation").fadeToggle("fast", function() {
                    $("#alert-submit-privatisation").fadeToggle("fast", "linear");
                });
            }
        },
    });

});
