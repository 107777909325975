$(document).ready(function() {
	if ($('#splide-image_caracteristique').length > 0) {
		new Splide( '#splide-image_caracteristique', {
			type   : 'loop',
			perPage: 1,
			autoplay: true,
			interval: 10000,
			pagination: false,
			arrows: false,
		} ).mount();
	}

	if ($('#splide-velos_similaire').length > 0) {
		new Splide( '#splide-velos_similaire', {
			type   : 'loop',
			perPage: 3,
			autoplay: true,
			interval: 10000,
			pagination: true,
			breakpoints: {
				767.98: {
					perPage: 2,
				},
				575.98: {
					perPage: 1,
				},

			},
			pagination: false,
		} ).mount();
	}

	$('body').on("click", "#button-go-to-financement", function() {
		$([document.documentElement, document.body]).animate({
	        scrollTop: $(".container-financement").offset().top - 100
	    }, 0);
	});



	// Change image en fonction de la couleur
	$("[data-numbercolor]").on("click", function() {
		// console.log($(this).data("numbercolor"));
		color = $(this);
		if ($('#img-principale').attr("src") != color.data('srcphoto')){
			$('#img-principale').fadeOut(500, function() {
	      $('#img-principale').attr("src", color.data('srcphoto'));
	      $('#img-principale').fadeIn(500);
	    });
		}
	});

	$('#produit-guide_taille').on('click', function() {
		$('.guide-taille-modal').modal('show');
	});

	// Afficher la categorie demander
	$("[id^='button-next-contact-']").on("click", function() {
	  var categorie = this.id.split(/[- ]+/).pop();
	  $("#modal-home, #modal-mail, #modal-pimp, #modal-location").removeClass('d-block').addClass('d-none');
	  $('#modal-'+categorie).removeClass('d-none').addClass('d-block');
	  $("#button-modal-previous-contact").removeClass('d-none').addClass('d-block');
	  // $("#button-modal-previous-contact").data("previousmodal", "home");
    $('#modal-content-border-contact').removeClass(function (index, className) {
		    return (className.match (/(^|\s)modal-content-\S+/g) || []).join(' ');
		});
		$('#modal-content-border-contact').addClass("modal-content-"+categorie);

		if (categorie == "mail") {
			$("#modal-mail").find("#modal-to-access-mail").html($(this).data("accessmodal"));
			if ($(this).data("accessmodal") == "pimp") {
				$("#modal-mail").find(".title-modal-mail").html("Recevez votre configuration");
			} else {
				$("#modal-mail").find(".title-modal-mail").html("Nous contacter par mail");
			}
		}
		console.log($(this).data("accessmodal"));
		$("#button-modal-previous-contact").data("returnmodal", $(this).data("accessmodal"));

	});
	// Revenir a l'accueil de la modal
	$("#button-modal-previous-contact").on('click', function() {
		console.log($(this).data("previousmodal"));
	  $("#modal-home, #modal-mail, #modal-devis, #modal-pimp, #modal-location").removeClass('d-block').addClass('d-none');
	  previous = "home";
	  if ($(this).data("returnmodal") != null) {
	  	previous = $(this).data("returnmodal");
	  }
	  $('#modal-'+previous).removeClass('d-none').addClass('d-block');
	  if (previous == "home") {
	  	$("#button-modal-previous-contact").removeClass('d-block').addClass('d-none');
	  } else {
	  	$(this).data("returnmodal", "home");
	  }
	  

	  $('#modal-content-border-contact').removeClass(function (index, className) {
		    return (className.match (/(^|\s)modal-content-\S+/g) || []).join(' ');
		});
		$('#modal-content-border-contact').addClass("modal-content-"+previous);
	});

	// Open MODAL PIMP Direct
	$("#open-modal-pimp").on("click", function() {
		$("#modalContact").modal("show");
		$("#modal-home, #modal-mail, #modal-devis, #modal-pimp, #modal-location").removeClass('d-block').addClass('d-none');
		$("#modal-pimp").toggleClass("d-block d-none");

		$("#button-modal-previous-contact").removeClass('d-none').addClass('d-block');
	  $("#button-modal-previous-contact").data("returnmodal", "home");

		$('#modal-content-border-contact').removeClass(function (index, className) {
		    return (className.match (/(^|\s)modal-content-\S+/g) || []).join(' ');
		});
		$('#modal-content-border-contact').addClass("modal-content-pimp");
	});

	// Afficher le numéro de téléphone dans la modal
	$("#display-phone-modal-contact").on("click", function() {
	  $("#span-phone-modal-contact").addClass('d-none');
	  $("#lien-phone-modal-contact").removeClass('d-none');
	});

	// Envoie du mail
	$('body').on('click', '#popup_contact_mail', function() {
	  filledForm = true;
	  // Check good formulaire
	  $("#modal-mail").find('input[id^="contact_"]').each( function() {
	      if ($(this).attr("type") == "text" || $(this).attr("type") == "email") {
	          if ($(this).val() == "") {
	            $(this).css("border", "1px solid red");
	            filledForm = false;
	          }
	      }
	  });

	  if (filledForm) {
	  	contact = [];
	  	contact['email'] = $("#modal-mail").find("#contact_email").val();
	  	contact['nom'] = $("#modal-mail").find("#contact_nom").val();
	  	contact['prenom'] = $("#modal-mail").find("#contact_prenom").val();
	  	contact['telephone'] = $("#modal-mail").find("#contact_phone").val();
	  	contact['sujet'] = $("#modal-mail").find("#contact_title").val();
	  	contact['message'] = $("#modal-mail").find("textarea").val();

	  	form = new FormData();
	  	if ($('#modal-to-access-mail').html() == "pimp") {
	  		form.append('pimp', true);
	  	} else {
	  		form.append('pimp', false);
	  	}
	  	if ($('#modal-to-access-mail').html() == "pimp") {
				if ($("input[name='pimp-color']").length > 0) {
					form.append('pimp-color', $("input[name='pimp-color']:checked").val());
				}
				if ($("input[name='pimp-taille']").length > 0) {
					form.append('pimp-taille', $("input[name='pimp-taille']:checked").val());
				}
				if ($("input[name='pimp-moteur']").length > 0) {
					form.append('pimp-moteur', $("input[name='pimp-moteur']:checked").val());
				}
				if ($("input[name='pimp-batterie']").length > 0) {
					form.append('pimp-batterie', $("input[name='pimp-batterie']:checked").val());
				}
				if ($("input[name='pimp-accessoire']").length > 0) {
					pimp_accessoire = "";
					$("input[name='pimp-accessoire']:checked").each(function() {
						pimp_accessoire += $(this).val() + ",";
					});
					// form.append('pimp-accessoire')
					form.append('pimp-accessoire', pimp_accessoire);
				}
			}
	  	form.append('email', contact['email']);
	  	form.append('nom', contact['nom']);
	  	form.append('prenom', contact['prenom']);
	  	form.append('telephone', contact['telephone']);
	  	form.append('sujet', contact['sujet']);
	  	form.append('message', contact['message']);
	  	form.append('annonce_id', $("#velo_id").html());
	  	// contact = $(contact).serializeArray();
	  	// console.log(form);
	    $.ajax({
	      type: 'POST',
	      url: siteURL + "contact_popup",
	      data: form,
	      cache: false,
        contentType: false,
        processData: false,
	      success: function(data) {
	        $("#formulaire-contact-mail").slideUp();
	        $("#modal-mail").find("#popup_contact_mail_envoye").slideDown();
	        // window.setTimeout(function() {
	        	// Fermeture de la modal si nécessaire ?     
	        // }, 4000);
	      },
	    });
	  }
	  return false;
	});

	$('.lien_produit-pdf').on('click', function() {
	 	if ($("#saisie-mail").is(":visible")) {
      $(".annonce-identifiant").animate({ 'padding': '68px' }, "slow", function() {
        $("#saisie-mail").hide();
      });

    } else {
      $(".annonce-identifiant").animate({ 'padding': '70px' }, "slow");
      $("#saisie-mail").show();

  	}
		return false;
	});

	// 
	$("[data-changeprice]").on("click", function() {
		console.log($(this).data("changeprice"));
		price = $("#pimp-price-final").html().split(" ")[0];

		if ($(this).attr('type') == "radio") {
			// RADIO BUTTON
			$("input[name='"+$(this).attr('name')+"']").each(function() {
				if ($(this).attr('checked') == "checked") {
					if ($(this).data("changeprice")) {
						price = parseFloat(price) - $(this).data("changeprice");
						$(this).attr('checked', false);
					}
				}
				// $("#pimp-price-final").html(price + " €");
			});
			price = parseFloat(price) + $(this).data("changeprice");
			price = Math.round(price*100)/100;
			$("#pimp-price-final").html(price + " €");
			$(this).attr('checked', true);
		} else {
			// CHECKBOX
			
		
			if ($(this).is(":checked")) {
				price = parseFloat(price) + $(this).data("changeprice");
				
			} else {
				price = parseFloat(price) - $(this).data("changeprice");
			}
			price = Math.round(price*100)/100;
			$("#pimp-price-final").html(price + " €");
		}
		
	});

	// $("body").on("click", "#popup_contact_devis", function() {
	// 	form = new FormData();
		

	// 	form.append('devis-nom', $("input[name='devis_nom']").val());
	// 	form.append('devis-prenom', $("input[name='devis_prenom']").val());
	// 	form.append('devis-email', $("input[name='devis_email']").val());
	// 	form.append('devis-telephone', $("input[name='devis_telephone']").val());
	// 	form.append('devis-codepostal', $("input[name='devis_codepostal']").val());
	// 	form.append('devis-ville', $("input[name='devis_ville']").val());

	// 	form.append('devis-achat', $("input[name='devis-offre-achat']:checked").val());

	// 	// console.log($("input[name='devis-offre-achat']:checked").val());


	// });

	// https://xdsoft.net/jqplugins/datetimepicker/
	$.datetimepicker.setLocale('fr');

	var dateEssai = function( currentDateTime ){
  // 'this' is jquery object datetimepicker
  	// console.log(currentDateTime.toDateString());
  	form = new FormData();

  	datetimepicker = this;

  	form.append('date',currentDateTime.toDateString());
  	$.ajax({
      type: 'POST',
      url: siteURL + "check-date/essai-hours",
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function(data) {
      	datetimepicker.setOptions({
      		allowTimes: data.split(",")
      	});
      },
    });
	  if( currentDateTime.getDay()==6 ){
	    this.setOptions({
	      minTime:'10:30',
	      maxTime:'16:31'
	      // allowTimes: []
	    });
	  }else
	    this.setOptions({
	      minTime:'10:00',
	      maxTime:'18:01'
	      // allowTimes: []
	    });
	};

	$("#form-essai-date").datetimepicker({
		format: 'j F Y, H:i',
		lang: 'fr',
		step: 30,
		minDate: '+1970/01/03',
		validateOnBlur: false,
		timepicker: false,
		dayOfWeekStart: 1,
		disabledWeekDays: [0, 1],
		onChangeDateTime: dateEssai,
		onShow: dateEssai,
		onSelectDate: function(){
		  $('#form-essai-date').datetimepicker('setOptions', {
			timepicker: true
		  });
		}
  });

  $('body').on("click", '#button-submit-essai', function () {
  	input_valide = true;
  	$("input[name^='essai_']").each(function() {
  		
			if ($(this).val() != "") {
				$(this).css("border", '1px dashed #cecac1');
			} else {
				$(this).css("border", '1px dashed red');
				input_valide = false;
			}

  	});

  	// Regex Telephone
  	regex = new RegExp(/^\d{10}$/);
  	if (!(regex.test($("input[name='essai_telephone']").val()))) {
  		$("input[name='essai_telephone']").css("border", '1px dashed red');
  		input_valide = false;
  	} else {
  		$("input[name='essai_telephone']").css("border", '1px dashed #cecac1');
  	}

  	// Regex Mail
  	regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  	if (!(regex.test($("input[name='essai_email']").val()))) {
  		$("input[name='essai_email']").css("border", '1px dashed red');
  		input_valide = false;
  	}	else {
  		$("input[name='essai_email']").css("border", '1px dashed #cecac1');
  	}

  	// TODO : CHECK DATE
  	if ($('#form-essai-date').datetimepicker('getValue').getMinutes() != 0 && $('#form-essai-date').datetimepicker('getValue').getMinutes() != 30) {
  		$("input[name='essai_date']").css("border", '1px dashed red');
  		input_valide = false;
  	} else {
  		$("input[name='essai_date']").css("border", '1px dashed #cecac1');
  	}

  	if (input_valide == false) {
  		return false;
  	}

  	// console.log("ok");

  	form = new FormData();
		
		form.append('essai-nom', $("input[name='essai_nom']").val());
		form.append('essai-prenom', $("input[name='essai_prenom']").val());
		form.append('essai-email', $("input[name='essai_email']").val());
		form.append('essai-telephone', $("input[name='essai_telephone']").val());
		form.append('essai-taille', $("input[name='essai_taille']").val());
		form.append('essai-velo', $("input[name='essai_modele']").data("veloid"));
		form.append('essai-date', $('#form-essai-date').datetimepicker('getValue').toString());
		form.append("essai-commentaire", $("textarea[name='essai_commentaire']").val());


		$.ajax({
      type: 'POST',
      url: siteURL + "velo/essai",
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function(data) {
      	console.log(data);
        if (data.trim() == "ok") {
        	$("#form-essai-velo").slideUp();
	        $("#modal-essai").find("#popup_essai_mail_envoye").slideDown();
	        window.setTimeout(function() {
	            // fancybox_contactvehicule.close();
	        }, 4000);
        }
      },
    });

	});

	$('body').on("click", "#pop-up-location-velo", function() {
		form = new FormData();
		form.append("velo_id", $("#velo_id").text());

		console.log($("#velo_id").text());

		$.ajax({
			type: 'POST',
			url: siteURL + "pop-up/location",
			data: form,
			cache: false,
			contentType: false,
			processData: false,
			success: function(data) {
				$("#modal-home, #modal-mail, #modal-pimp, #modal-location").removeClass('d-block').addClass('d-none');
				$("#modal-location").removeClass('d-none').removeClass('d-block');

				$('#modal-location').html("");
				$('#modal-location').append(data);
				$("#modalContact").modal("show");
				$.datetimepicker.setLocale('fr');

				var dateLocationDebut = function( currentDateTime ){
			  	// 'this' is jquery object datetimepicker
			  	// console.log(currentDateTime.toDateString());
			  	// form = new FormData();

			  	// datetimepicker = this;

			  	// form.append('date',currentDateTime.toDateString());
			  	// $.ajax({
			   //    	type: 'POST',
			   //    	url: siteURL + "check-date/essai-hours",
			   //    	data: form,
			   //    	cache: false,
			   //    	contentType: false,
			   //    	processData: false,
			   //    	success: function(data) {
				  //     	datetimepicker.setOptions({
				  //     		allowTimes: data.split(",")
				  //     	});
			   //    	},
			   //  });
			  	if( currentDateTime.getDay()==6 ){
				    this.setOptions({
				      	minTime:'10:00',
				      	maxTime:'18:01'
				      	// allowTimes: []
				    });
			  	}else
				    this.setOptions({
				      	minTime:'9:00',
				      	maxTime:'19:01'
				      	// allowTimes: []
				    });
				};

				var dateLocationDebutChange = function( currentDateTime ){

					$('#form-date-fin-location').datetimepicker('reset')
			  	// 'this' is jquery object datetimepicker
			  	// console.log(currentDateTime.toDateString());
			  	// form = new FormData();

			  	// datetimepicker = this;

			  	// form.append('date',currentDateTime.toDateString());
			  	// $.ajax({
			   //    	type: 'POST',
			   //    	url: siteURL + "check-date/essai-hours",
			   //    	data: form,
			   //    	cache: false,
			   //    	contentType: false,
			   //    	processData: false,
			   //    	success: function(data) {
				  //     	datetimepicker.setOptions({
				  //     		allowTimes: data.split(",")
				  //     	});
			   //    	},
			   //  });
			  	if( currentDateTime.getDay()==6 ){
				    this.setOptions({
				      	minTime:'10:00',
				      	maxTime:'18:01'
				      	// allowTimes: []
				    });
			  	}else
				    this.setOptions({
				      	minTime:'9:00',
				      	maxTime:'19:01'
				      	// allowTimes: []
				    });
				};

				var dateLocationFin = function( currentDateTime ){

					date_debut = $('#form-date-debut-location').datetimepicker('getValue').toLocaleDateString("fr-FR");


					console.log(date_debut);

			  	// 'this' is jquery object datetimepicker
			  	// console.log(currentDateTime.toDateString());
			  	// form = new FormData();

			  	// datetimepicker = this;

			  	// form.append('date',currentDateTime.toDateString());
			  	// $.ajax({
			   //    	type: 'POST',
			   //    	url: siteURL + "check-date/essai-hours",
			   //    	data: form,
			   //    	cache: false,
			   //    	contentType: false,
			   //    	processData: false,
			   //    	success: function(data) {
				  //     	datetimepicker.setOptions({
				  //     		allowTimes: data.split(",")
				  //     	});
			   //    	},
			   //  });
			   	this.setOptions({
			   		allowDates: [date_debut],
			   		formatDate:'d/m/Y'
			   	});
			  	if( currentDateTime.getDay()==6 ){
				    this.setOptions({
				      	minTime:'10:00',
				      	maxTime:'18:01'
				      	// allowTimes: []
				    });
			  	}else
				    this.setOptions({
				      	minTime:'9:00',
				      	maxTime:'19:01'
				      	// allowTimes: []
				    });
				};

	

				$('body').find("#form-date-debut-location").datetimepicker({
			    format:'j F Y, H:i',
			  	lang:'fr',
			  	step:60,
			  	minDate:0,
			  	validateOnBlur:false,
			  	dayOfWeekStart: 1,
			  	disabledWeekDays: [0],
			  	onChangeDateTime:dateLocationDebutChange,
			  	onShow:dateLocationDebut
		  	});

		  	$('body').find("#form-date-fin-location").datetimepicker({
			    format:'j F Y, H:i',
			  	lang:'fr',
			  	step:60,
			  	minDate:0,
			  	validateOnBlur:false,
			  	dayOfWeekStart: 1,
			  	disabledWeekDays: [0],
			  	onChangeDateTime:dateLocationFin,
			  	onShow:dateLocationFin
		  	});
			}
		});
	});
	  
});