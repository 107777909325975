$(document).ready(function() {
	var siteURL = (function() { return $('#get_host').html();})();
	$('#mobile_menu').css('display','block');
	$('#mobile_menu_background').css('display', 'initial');
	var IE11 = !!window.msCrypto;
	isSafari = !!navigator.userAgent.match(/safari/i) && !navigator.userAgent.match(/chrome/i) && typeof document.body.style.webkitFilter !== "undefined" && !window.chrome;

	

	// $("div[data-target]").on("click", function() {
	// 	$($(this).attr('data-target')).modal("show");
	// });

	$("#lire-plus-button").on("click", function() {
		for (const iterator of $('[name="lire-plus-target"]')) {
			$(iterator).removeAttr('name');
		}
		$("#lire-plus-container").addClass("d-none")
	})
});