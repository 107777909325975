$(document).ready(function() {
	// if ($('#splide-home').length > 0) {
	// 	new Splide( '#splide-home', {
	// 		type   : 'loop',
	// 		perPage: 1,
	// 		autoplay: true,
	// 		interval: 10000,
	// 	} ).mount();
	// }

	// if ($('#splide-nouveautes').length > 0) {
	// 	new Splide( '#splide-nouveautes', {
	// 		type   : 'loop',
	// 		perPage: 4,
	// 		breakpoints: {
	// 			991.98: {
	// 				perPage: 3,
	// 			},
	// 			767.98: {
	// 				perPage: 2,
	// 			},
	// 			575.98: {
	// 				perPage: 1,
	// 			},

	// 		},
	// 		autoplay: true,
	// 		arrows: false,
	// 		interval: 15000,
	// 	} ).mount();
	// }
	
	if ($('#splide-actualites').length > 0) {
		new Splide( '#splide-actualites', {
			type   : 'loop',
			perPage: 3,
			breakpoints: {
				1199.98: {
					perPage: 2,
				},
				991.98: {
					perPage: 1,
				},

			},
			autoplay: true,
			arrows: false,
			interval: 17000,
		} ).mount();
	}

	if ($("#splide-marque-populaire").length > 0) {
		new Splide( '#splide-marque-populaire', {
			type   : 'loop',
			perPage: 8,
			breakpoints: {
				1499.98: {
					perPage: 6,
				},
				1199.98: {
					perPage: 5,
				},
				991.98: {
					perPage: 4,
				},
				575.98: {
					perPage: 2,
				},
			},
			perMove: 1,
			autoplay: true,
			arrows: false,
			interval: 5000,
		} ).mount();
	}

	if ($("#splide-espace-chill").length > 0) {
		new Splide( '#splide-espace-chill', {
			type   : 'loop',
			perPage: 3,
			breakpoints: {
				991.98: {
					perPage: 2,
				},
				767.98: {
					perPage: 1,
				},

			},
			focus: 'center',
			perMove: 1,
			autoplay: true,
			arrows: false,
			interval: 5000,
		} ).mount();
	}

	

	
});