$(document).ready(function() {
	$('body').on('click', '#button-less-number-casque', function() {
		if ($("#form-location-number-casque").val() > 0) {
			$("#form-location-number-casque").val(parseInt($("#form-location-number-casque").val()) - 1);
		}
	});

	$('body').on('click', '#button-more-number-casque', function() {
		$("#form-location-number-casque").val(parseInt($("#form-location-number-casque").val()) + 1);
	});

	$('body').on('click', "[id^='location-add-velo-']", function() {
		$nbr_velo = this.id.replace("location-add-velo-", "");

		$('#form-date-debut-location').datetimepicker('reset');
		$('#form-date-fin-location').datetimepicker('reset');

		$.ajax({
	      	type: 'POST',
	      	url: siteURL + "check-date/essai-hours",
	      	data: form,
	      	cache: false,
	      	contentType: false,
	      	processData: false,
	      	success: function(data) {
		      	datetimepicker.setOptions({
		      		allowTimes: data.split(",")
		      	});
	      	},
	    });


	});
});