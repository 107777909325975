$(document).ready(function() {
	$('#button-send-newsletter').on("click", function() {
		input_valide = true;
		// Regex Mail
	  	regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	  	if (!(regex.test($("#input-newsletter").val()))) {
	  		$("#input-newsletter").css("border", '1px dashed red');
	  		input_valide = false;
	  	}	else {
	  		$("#input-newsletter").css("border", 'none');
	  	}

	  	if (input_valide == false) {
	  		return false;
	  	}

	  	form = new FormData();

	  	form.append("email-newsletter", $("#input-newsletter").val());


	  	$.ajax({
	      type: 'POST',
	      url: siteURL + "newsletter-add",
	      data: form,
	      cache: false,
	      contentType: false,
	      processData: false,
	      success: function(data) {
	      	console.log(data);
	        if (data.trim() == "ok") {
	        	$("#form-newsletter").slideUp();
		        $("#results-form-newsletter").slideDown();
		        // window.setTimeout(function() {
		        //     // fancybox_contactvehicule.close();
		        // }, 4000);
	        }
	      },
	    });
	});

});