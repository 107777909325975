$(document).ready(function() {

	$('body').on("click", "[id^='access-categorie-']", function() {
		categorie = this.id.replace('access-categorie-', '');

		$([document.documentElement, document.body]).animate({
	        scrollTop: $("#presentation-accessoire-"+categorie).offset().top - 100
	    }, 0);
	});

});