$(window).on('resize', function(){
    var win = $(this);
    menu_position = $('#mobile_menu').position();
    if (win.width() >= 992 && menu_position.left == 0 ) {
    	$('#menu_burger').click();

    }
});

$(document).ready(function() {
	$('#menu_burger, #menu_burger_close').on('click', function() {
		if (typeof menu_mobile_display === 'undefined' || menu_mobile_display == 0) {
            //$('#page').css({"position":"fixed"});​
      // if (window.innerWidth > 575.98 &&  window.innerWidth < 767.98) {
      //   $('#mobile_menu').css({"transform":"translateX(275px)"});​
      // } else if (window.innerWidth > 767.98 && window.innerWidth < 991.98) {
      //   $('#mobile_menu').css({"transform":"translateX(370px)"});​
      // } else {
      //   $('#mobile_menu').css({"transform":"translateX(250px)"});​
      // }
      $('#mobile_menu').css({"transform":"translateX(100%)"});​
			// $('#mobile_menu').css({"transform":"translateX(370px)"});​
			//setTimeout( function() {
				// $('#mobile_menu_background').css({"transform":"translateX(100%)"});​
            	//$('#page').css({"opacity":"0.5"});​
       		//}, 1000);
      $('#menu_burger').removeClass('d-block');
      $('#menu_burger').addClass('d-none');
   		$('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
			menu_mobile_display = 1;
		}
		else {
      // if (window.innerWidth > 575.98 && window.innerWidth < 767.98) {
      //   $('#mobile_menu').css({"transform":"translateX(-275px)"});​
      // } else if (window.innerWidth > 767.98 && window.innerWidth < 991.98) {
      //   $('#mobile_menu').css({"transform":"translateX(-370px)"});​
      // } else {
      //   $('#mobile_menu').css({"transform":"translateX(-250px)"});​
      // }
      $('#mobile_menu').css({"transform":"translateX(-100%)"});
			
			//setTimeout( function() {
            	//$('#page').css({"opacity":"1"});​
            	// $('#mobile_menu_background').css({"transform":"translateX(-100%)"});​
            	//$('#page').css({"position":"initial"});​
            //}, 500);
      $('#menu_burger').removeClass('d-none');
      $('#menu_burger').addClass('d-block');

   		$('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
			menu_mobile_display = 0;
		}
	});

	$('.menu-link').on('click', function() {
		that = this;
		$('.menu-link').each( function () {
			if ($(this).next('.menu-sub').is(':visible') && this != that) {
				$(this).next('.menu-sub').slideToggle();
			}
		})
		$(this).next('.menu-sub').slideToggle();
	});

	// Sticky Menu
  var headerMenu = document.getElementById("sticky-header");
  // Hauteur du header
  if (headerMenu) {
      var offsetTop = headerMenu.offsetTop;
  }
  // Lorsque la page scrolle
  window.onload = window.onscroll = function() {
    if (headerMenu) {
        // Si l'on passe sous la hauteur du header
        if (window.pageYOffset >= 1) {
          headerMenu.classList.add("sticky");
        }
        // Lorsqu'on revient en haut
        else {
          headerMenu.classList.remove("sticky");
        }

        if (window.pageYOffset >= 20) {
          headerMenu.classList.add("sticky-box-shadow");
        } else {
          headerMenu.classList.remove("sticky-box-shadow");
        }
    }
  };


  // Gestion des pagers
  setInterval( function() {
    size_pagers = $('[id^="pager-"]').length - 1;
    // console.log(size_pagers);
    if (typeof initial_pager === 'undefined') { initial_pager = 0; next_pager = 0; }
    if (next_pager == size_pagers) { next_pager = 0; }
    else { next_pager = initial_pager + 1; }
    // $("#pager-"+initial_pager).fadeOut(400, function() {
    //     $("#pager-"+(next_pager)).fadeIn(400);
    // });

    $('#pager-'+initial_pager).removeClass('hide');
    $('#pager-'+initial_pager).addClass('hiden');
    $('#pager-'+next_pager).removeClass('hiden');
    $('#pager-'+next_pager).addClass('hide');

    initial_pager = next_pager;
  }, 20000);

	$('[name="header-cycles-link"]').on('mouseover', function() { // Affichage de l'image du sous menu "cycles"
    for (const iterator of $('[name="header-cycles-img"]')) {
      $(iterator).removeClass('d-inline');
    }
    $('[name="header-cycles-img"][data-categorie="'+$(this).attr("data-categorie")+'"]').addClass('d-inline');
	});

});