// Page Restauration
$(document).ready(function() {
	if ($('#splide-restauration').length > 0) {
		new Splide( '#splide-restauration', {
			type   : 'loop',
			perPage: 5,
			perMove: 1,
			focus: 'center',
			autoplay: true,
			interval: 5000,
			pagination: true,
			breakpoints: {
				991.98: {
					perPage: 3,
				},
				767.98: {
					perPage: 2,
				},
				575.98: {
					perPage: 1,
					interval: 3000,
				},

			},
			arrows: false,
		} ).mount();
	}
});