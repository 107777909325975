$(document).ready(function() {
	
	$("body").on("click", "[id^='categorie-faq-']", function() {
		categorie_faq = this.id.replace("categorie-faq-", "");
		// console.log($("#div-response-faq-"+categorie_faq).hasClass("display-none"));
		if ($("#div-response-faq-"+categorie_faq).hasClass("display-none") == true) {
			// console.log(categorie_faq);
			$("[id^='div-response-faq-']").each(function() {
				$(this).addClass("display-none");
			});
			$("#div-response-faq-"+categorie_faq).removeClass("display-none");
		}
		
	});

	$("body").on("click", ".div-question", function() {
		// console.log($(this).closest(".div-faq"));

		if ($(this).closest(".div-faq").hasClass('active')) {
			$(this).closest(".div-faq").removeClass("active");
			$(this).closest(".div-faq").find(".div-response").slideUp( "slow", function() {
	    		// Animation complete.
	  		});
		} else {
			$(this).closest(".div-faq").addClass("active");
			$(this).closest(".div-faq").find(".div-response").slideDown( "slow", function() {
	    		// Animation complete.
	  		});
		}

		$(this).find(".chevron-question").toggleClass("fa-chevrons-down fa-chevrons-up");

	});

});