// Page Atelier
$(document).ready(function () {
  if ($('#splide-atelier').length > 0) {
    new Splide('#splide-atelier', {
      type: 'loop',
      perPage: 4,
      autoplay: true,
      interval: 5000,
      pagination: false,
      breakpoints: {
        991.98: {
          perPage: 3,
        },
        767.98: {
          perPage: 2,
        },
        575.98: {
          perPage: 1,
          interval: 3000,
        },
      },
      arrows: false,
    }).mount()
  }

  // $('#button-diag-velo').on('click', function () {
  //   $('#modal-home').removeClass('d-block').addClass('d-none')

  //   $('#modal-diag-velo').removeClass('d-none').addClass('d-block')
  //   $('#button-modal-atelier-back-home')
  //     .removeClass('d-none')
  //     .addClass('d-block')
  // })

  // $('#button-reparation-express').on('click', function () {
  //   $('#modal-home').removeClass('d-block').addClass('d-none')

  //   $('#modal-reparation-express').removeClass('d-none').addClass('d-block')
  //   $('#button-modal-atelier-back-home')
  //     .removeClass('d-none')
  //     .addClass('d-block')
  // })

  // $('#button-modal-atelier-back-home').on('click', function () {
  //   $('#modal-diag-velo').removeClass('d-block').addClass('d-none')
  //   $('#modal-reparation-express').removeClass('d-block').addClass('d-none')
  //   $('#modal-home').removeClass('d-none').addClass('d-block')

  //   $(this).removeClass('d-block').addClass('d-none')
  // })

  $.datetimepicker.setLocale('fr')

  var dateDiagnostic = function (currentDateTime) {
    form = new FormData()

    datetimepicker = this

    form.append('date', currentDateTime.toDateString())
    form.append('duree', 30)

    $.ajax({
      type: 'POST',
      url: siteURL + 'check-date/atelier-diagnostic',
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        datetimepicker.setOptions({
          allowTimes: data.split(','),
        })
      },
    })

    if (currentDateTime.getDay() == 6) {
      this.setOptions({
        minTime: '10:30',
        maxTime: '16:31',
        // allowTimes: []
      })
    } else
      this.setOptions({
        minTime: '10:00',
        maxTime: '17:31',
        // allowTimes: []
      })
  }

  $('#form-diag-velo-date').datetimepicker({
    format: 'j F Y, H:i',
    lang: 'fr',
    step: 30,
    minDate: '+1970/01/03',
    validateOnBlur: false,
    timepicker: false,
    dayOfWeekStart: 1,
    disabledWeekDays: [0, 1],
    onChangeDateTime: dateDiagnostic,
    onShow: dateDiagnostic,
    onSelectDate: function(){
      $('#form-diag-velo-date').datetimepicker('setOptions', {
        timepicker: true
      });
    }
  })

  $('#submit-formulaire-diag-velo').on('click', function () {
    input_valide = true
    $("input[name^='diag_']").each(function () {
      if ($(this).attr('name') != 'diag_velo') {
        if ($(this).val() != '') {
          $(this).css('border', '1px dashed #cecac1')
        } else {
          $(this).css('border', '1px dashed red')
          input_valide = false
        }
      }
    })

    // Regex Telephone
    regex = new RegExp(/^\d{10}$/)
    if (!regex.test($("input[name='diag_telephone']").val())) {
      $("input[name='diag_telephone']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='diag_telephone']").css('border', '1px dashed #cecac1')
    }

    // Regex Mail
    regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!regex.test($("input[name='diag_email']").val())) {
      $("input[name='diag_email']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='diag_email']").css('border', '1px dashed #cecac1')
    }

    // TODO : CHECK DATE
    if (
      $('#form-diag-velo-date').datetimepicker('getValue').getMinutes() != 0 &&
      $('#form-diag-velo-date').datetimepicker('getValue').getMinutes() != 30
    ) {
      $("input[name='diag_date']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='diag_date']").css('border', '1px dashed #cecac1')
    }

    if (input_valide == false) {
      return false
    }

    form = new FormData()

    form.append('diag-nom', $("input[name='diag_nom']").val())
    form.append('diag-prenom', $("input[name='diag_prenom']").val())
    form.append('diag-email', $("input[name='diag_email']").val())
    form.append('diag-telephone', $("input[name='diag_telephone']").val())

    form.append('diag-velo', $("input[name='diag_velo']").val())
    // console.log($('#form-essai-date').datetimepicker('getValue').toString());
    form.append(
      'diag-date',
      $('#form-diag-velo-date').datetimepicker('getValue').toString(),
    )

    $.ajax({
      type: 'POST',
      url: siteURL + 'atelier/rdv/diagnostic',
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        console.log(data)
        if (data.trim() == 'ok') {
          $('#form-diag-velo').slideUp()
          $('#modal-diag-velo').find('#popup_diag_mail_envoye').slideDown()
          window.setTimeout(function () {
            // fancybox_contactvehicule.close();
          }, 4000)
        }
      },
    })
  })

  $("input[id^='checkbox-reparation-']").on('change', function () {
    reparation = this.id.replace('checkbox-reparation-', '')
    nbr_reparation = $('#row-checkbox-reparation').find(
      "input[id^='checkbox-reparation-']:checked",
    ).length
    if (this.checked) {
      if (nbr_reparation == 1) {
        $('#notification-reparation-' + reparation).html('+ 30 min + 15 €')
        $('#tilte-checkbox-reparation').css('color', 'unset')
      } else {
        $('#notification-reparation-' + reparation).html('+ 15 min + 5 €')
      }
    } else {
      $('#notification-reparation-' + reparation).html('&nbsp;')
      if (nbr_reparation == 1) {
        new_reparation = $('#row-checkbox-reparation')
          .find("input[id^='checkbox-reparation-']:checked")[0]
          .id.replace('checkbox-reparation-', '')
        $('#notification-reparation-' + new_reparation).html('+ 30 min + 15 €')
      }
    }
    $('#form-reparation-velo-date').datetimepicker('reset')
  })

  var dateReparation = function (currentDateTime) {
    // 'this' is jquery object datetimepicker
    // console.log(currentDateTime.toDateString());
    form = new FormData()

    datetimepicker = this

    form.append('date', currentDateTime.toDateString())
    // $("#row-checkbox-reparation").find("input[id^='checkbox-reparation-']:checked").length
    if (
      $('#row-checkbox-reparation').find(
        "input[id^='checkbox-reparation-']:checked",
      ).length == 0
    ) {
      duree_reparation = 0
    } else {
      duree_reparation =
        30 +
        15 *
          ($('#row-checkbox-reparation').find(
            "input[id^='checkbox-reparation-']:checked",
          ).length -
            1)
    }
    form.append('duree', duree_reparation)

    // console.log(duree_reparation)

    $.ajax({
      type: 'POST',
      url: siteURL + 'check-date/atelier-reparation',
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        datetimepicker.setOptions({
          allowTimes: data.split(','),
        })
      },
    })
    if (currentDateTime.getDay() == 6) {
      this.setOptions({
        minTime: '10:30',
        maxTime: '16:31',
        // allowTimes: []
      })
    } else
      this.setOptions({
        minTime: '10:00',
        maxTime: '17:31',
        // allowTimes: []
      })
  }

  $('#form-reparation-velo-date').datetimepicker({
    format: 'j F Y, H:i',
    lang: 'fr',
    step: 30,
    minDate: '+1970/01/03',
    validateOnBlur: false,
    timepicker: false,
    dayOfWeekStart: 1,
    disabledWeekDays: [0, 1],
    onChangeDateTime: dateReparation,
    onShow: dateReparation,
    onSelectDate: function(){
      $('#form-reparation-velo-date').datetimepicker('setOptions', {
        timepicker: true
      });
    }
  })

  $('#submit-formulaire-reparation-velo').on('click', function () {
    input_valide = true
    $("input[name^='reparation_']").each(function () {
      if ($(this).attr('name') != 'reparation_velo') {
        if ($(this).val() != '') {
          $(this).css('border', '1px dashed #cecac1')
        } else {
          $(this).css('border', '1px dashed red')
          input_valide = false
        }
      }
    })

    // Regex Telephone
    regex = new RegExp(/^\d{10}$/)
    if (!regex.test($("input[name='reparation_telephone']").val())) {
      $("input[name='reparation_telephone']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='reparation_telephone']").css(
        'border',
        '1px dashed #cecac1',
      )
    }

    // Regex Mail
    regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!regex.test($("input[name='reparation_email']").val())) {
      $("input[name='reparation_email']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='reparation_email']").css('border', '1px dashed #cecac1')
    }

    // TODO : CHECK DATE
    if (
      $('#form-reparation-velo-date').datetimepicker('getValue').getMinutes() !=
        0 &&
      $('#form-reparation-velo-date').datetimepicker('getValue').getMinutes() !=
        30
    ) {
      $("input[name='reparation_date']").css('border', '1px dashed red')
      input_valide = false
    } else {
      $("input[name='reparation_date']").css('border', '1px dashed #cecac1')
    }

    // Checkbox
    if (
      $('#row-checkbox-reparation').find(
        "input[id^='checkbox-reparation-']:checked",
      ).length == 0
    ) {
      $('#tilte-checkbox-reparation').css('color', 'red')
      input_valide = false
    }

    if (input_valide == false) {
      return false
    }

    form = new FormData()

    form.append('reparation-nom', $("input[name='reparation_nom']").val())
    form.append('reparation-prenom', $("input[name='reparation_prenom']").val())
    form.append('reparation-email', $("input[name='reparation_email']").val())
    form.append(
      'reparation-telephone',
      $("input[name='reparation_telephone']").val(),
    )

    form.append('reparation-velo', $("input[name='reparation_velo']").val())
    // console.log($('#form-essai-date').datetimepicker('getValue').toString());
    form.append(
      'reparation-date',
      $('#form-reparation-velo-date').datetimepicker('getValue').toString(),
    )

    reparation_checkbox = ''

    $('#row-checkbox-reparation')
      .find("input[id^='checkbox-reparation-']:checked")
      .each(function () {
        reparation_checkbox += $(this).val() + ','
      })

    form.append('reparation-reparation', reparation_checkbox)

    $.ajax({
      type: 'POST',
      url: siteURL + 'atelier/rdv/reparation',
      data: form,
      cache: false,
      contentType: false,
      processData: false,
      success: function (data) {
        console.log(data)
        if (data.trim() == 'ok') {
          $('#form-reparation-velo').slideUp()
          $('#modal-reparation-express')
            .find('#popup_reparation_mail_envoye')
            .slideDown()
          window.setTimeout(function () {
            // fancybox_contactvehicule.close();
          }, 4000)
        }
      },
    })
  });

  $("body").on("click", "[id^='button-reparation-express-']", function() {
    let id = this.id.replace('button-reparation-express-', '');
    console.log(id);
    $('#checkbox-reparation-'+id)[0].checked = true;
    console.log($('#checkbox-reparation-'+id));
    console.log($('#checkbox-reparation-'+id)[0].checked);
  })
})
