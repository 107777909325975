$(document).ready(function() {
	if ($('#publicite-slider').length > 0) {
		new Splide( '#publicite-slider', {
			type	   : 'loop',
			perPage    : 1,
			arrows	   : false,
			autoplay   : true,
		} ).mount();
	}

	$('.publicite_slide').on('click', function() {
		// Si le champs est présent
		if (this.dataset.link != null ) {
			window.location.href = this.dataset.link;
		}		
	});

	// $('#recherche_tri').on('change', function() {
	// 	console.log($('#recherche_tri option:selected').val());
	// 	tri = $('#recherche_tri option:selected').val().split('-');

	// 	marque = $('#recherche_tri').data('marque');
	// 	categorie = $('#recherche_tri').data('categorie');

	// 	var form = new FormData();

	// 	form.append('tri_', tri[0]);
	// 	form.append('tri_ordre', tri[1]);
	// 	form.append('marque', marque);
	// 	form.append('categorie', categorie);

	// 	$.ajax({
	// 		type: 'POST',
	// 		url: '../../produits_tri',
	// 		cache: false,
	// 		contentType: false,
	// 		processData: false,
	// 		data: form,
	// 		success: function(data) {

				
				
	// 		}
	// 	});
	// });
});
