$(document).ready(function() {

	optionsRecherche = {};

	$("[id^='formsearch_']").each(function() {
		search_id = this.id.replace('formsearch_', '');

		switch (search_id) {
			case 'marque':
				optionsRecherche['marque'] = $('#formsearch_'+search_id).html();
				$('#formsearch_'+search_id).html().split(',').forEach(function(marque) {
					if (marque != '') {
						$("#search_marque p[data-value='marque_"+marque+"']").addClass("item-search-active");
						$("#inputs-search-recap").append("<div class='input-search-recap input-search-recap-marque'><i id='delete-search-"+marque+"' data-search='marque' class='fa-solid fa-xmark'></i><span>"+$("#search_marque p[data-value='marque_"+marque+"']").text()+"</span></div>");
					}
				})


				// console.log(optionsRecherche['marque']);
				break;

			case 'categorie':
				optionsRecherche['categorie'] = $('#formsearch_'+search_id).html();
				$('#formsearch_'+search_id).html().split(',').forEach(function(categorie) {
					if (categorie != '') {
						$("#search_categorie p[data-value='categorie_"+categorie+"']").addClass("item-search-active");
						$("#inputs-search-recap").append("<div class='input-search-recap input-search-recap-categorie'><i id='delete-search-"+categorie+"' data-search='categorie' class='fa-solid fa-xmark'></i><span>"+$("#search_categorie p[data-value='categorie_"+categorie+"']").text()+"</span></div>");
					}
				})


				// console.log(optionsRecherche['categorie']);
				break;

			case 'sortby':
				optionsRecherche['sortby'] = $('#formsearch_'+search_id).html();
				if (optionsRecherche['sortby'] != "") {
					$("#recherche_tri").val("filtre-"+optionsRecherche['sortby']);
				}
				break;
		}
	});

	// =======
	// AFFICHER LES FILTRES
	// =======
	$("#button-display-modal-filter").on("click", function() {
		$("#modal-produit_search").css("display", "block");
	});

	$("#button-close-modal-filter, #button-validate-modal-filter").on("click", function() {
		$("#modal-produit_search").css("display", "none");
	});

	window.onclick = function(event) {
		if (event.target == document.getElementById("modal-produit_search")) {
	   		$("#modal-produit_search").css("display", "none");
	  	}
	}


	// =======
	// MARQUE
	// =======
	$("body").on("click", "p[data-value^='marque_']", function() {
		id_marque = $(this).attr('data-value').replace('marque_', '');
		console.log(id_marque);
		if ($(this).hasClass('item-search-active')) {
			// Supprimer la marque sélectionner
			$(this).removeClass('item-search-active');

			marque_array = optionsRecherche['marque'].split(',');

			const marque_array_index = marque_array.indexOf(id_marque);
			if (marque_array_index > -1) {
			  marque_array.splice(marque_array_index, 1);
			}

			$("#inputs-search-recap .input-search-recap i").each(function(item) {
				if ($(this).data("search") == "marque" && this.id.replace("delete-search-", "") == id_marque) {
					$(this).parent().remove();
				}
				// console.log($(this).data("search"));
			});

			optionsRecherche['marque'] = marque_array.join(',');

		} else {
			// Ajouter la marque sélectionner
			$(this).addClass('item-search-active');
			// console.log(optionsRecherche['marque']);
			$("#inputs-search-recap").append("<div class='input-search-recap input-search-recap-marque'><i id='delete-search-"+id_marque+"' data-search='marque' class='fa-solid fa-xmark'></i><span>"+$(this).text()+"</span></div>");

			if (optionsRecherche['marque'] == "") {
				optionsRecherche['marque'] = id_marque;
			} else {
				optionsRecherche['marque'] += "," + id_marque;
			}
			console.log(optionsRecherche['marque']);


			// $('#formsearch_'+search_id).html($('#formsearch_'+search_id).html() + "," +id_marque)
		}
		$('#formsearch_marque').html(optionsRecherche['marque']);

		updatePage(optionsRecherche);

		updateURL(optionsRecherche);

		updateSEO(optionsRecherche);
		// console.log(optionsRecherche);
	});

	// =======
	// CATEGORIE
	// =======
	$("body").on("click", "p[data-value^='categorie_']", function() {
		id_categorie = $(this).attr('data-value').replace('categorie_', '');
		if ($(this).hasClass('item-search-active')) {
			// Supprimer la catégorie sélectionner
			$(this).removeClass('item-search-active');

			categorie_array = optionsRecherche['categorie'].split(',');
			const categorie_array_index = categorie_array.indexOf(id_categorie);
			if (categorie_array_index > -1) {
			  	categorie_array.splice(categorie_array_index, 1);
			}

			$("#inputs-search-recap .input-search-recap i").each(function(item) {
				if ($(this).data("search") == "categorie" && this.id.replace("delete-search-", "") == id_categorie) {
					$(this).parent().remove();
				}
				// console.log($(this).data("search"));
			});

			optionsRecherche['categorie'] = categorie_array.join(',');
			// console.log(optionsRecherche['categorie']);
		} else {
			// Ajouter la categorie sélectionner
			// $(this).addClass('item-search-active');
			$(this).addClass('item-search-active');

			$("#inputs-search-recap").append("<div class='input-search-recap input-search-recap-categorie'><i id='delete-search-"+id_categorie+"' data-search='categorie' class='fa-solid fa-xmark'></i><span>"+$(this).text()+"</span></div>");
			// console.log(optionsRecherche['categorie']);
			if (optionsRecherche['categorie'] == "") {
				optionsRecherche['categorie'] = id_categorie;
			} else {
				optionsRecherche['categorie'] += "," + id_categorie;
			}
			// optionsRecherche['categorie'] += "," + id_categorie;
		}

		$('#formsearch_categorie').html(optionsRecherche['categorie']);

		updatePage(optionsRecherche);

		updateURL(optionsRecherche);

		updateSEO(optionsRecherche);
	});

	// =======
	// SELECTION DELETE SEARCH
	// =======
	$("body").on("click", "[id^='delete-search-']", function() {
		id_search = this.id.replace("delete-search-", "");

		// $(this).data("search") = marque ou categorie

		if ($(this).data("search") == "marque") {
			// Suppression d'une marque
			$("p[data-value='marque_"+id_search+"']").removeClass("item-search-active");

			marque_array = optionsRecherche['marque'].split(',');

			const marque_array_index = marque_array.indexOf(id_search);
			if (marque_array_index > -1) {
			  marque_array.splice(marque_array_index, 1);
			}

			optionsRecherche['marque'] = marque_array.join(',');

			$('#formsearch_marque').html(optionsRecherche['marque']);
		} else {
			// Suppression d'une categorie
			$("p[data-value='categorie_"+id_search+"']").removeClass("item-search-active");

			categorie_array = optionsRecherche['categorie'].split(',');

			const categorie_array_index = categorie_array.indexOf(id_search);
			if (categorie_array_index > -1) {
			  	categorie_array.splice(categorie_array_index, 1);
			}

			optionsRecherche['categorie'] = categorie_array.join(',');

			$('#formsearch_categorie').html(optionsRecherche['categorie']);
		}

		$(this).parent().remove();

		updatePage(optionsRecherche);

		updateURL(optionsRecherche);

		updateSEO(optionsRecherche);
	});

	// =======
	// TRIE
	// =======
	$('body').on("change", "#recherche_tri", function() {
		optionsRecherche['sortby'] = $(this).val().replace("filtre-", "");

		updatePage(optionsRecherche);

		updateURL(optionsRecherche);
	});

	// =======
	// DISPLAY MORE
	// =======
	$("body").on("click", "#button-more-velo", function() {
		page = $(this).attr('data-numberpage');

		optionsRecherche['page'] = page;

		// console.log(optionsRecherche);
		moreDisplayPage(optionsRecherche);
	});

	// =======
	// CLICK BTN PROMO
	// =======
	$("body").on("click", "#btn-promo", function() {
		if ($(this).hasClass("bg-tangerine")) {
			$(this).removeClass("bg-tangerine");
			$(this).addClass("bg-deepcyan");
			optionsRecherche['promo'] = "promo";
		} else if ($(this).hasClass("bg-deepcyan")) {
			$(this).removeClass("bg-deepcyan");
			$(this).addClass("bg-tangerine");
			optionsRecherche['promo'] = null;
		}

		updatePage(optionsRecherche);

		updateURL(optionsRecherche);
	});

});


// Mets à jour le contenu de la page de recherche dynamiquement
function updatePage(optionsRecherche) {
    // menuRecherche = optionsRecherche;

    if (typeof updating !== 'undefined') { updating.abort(); }
    $('#results').slideUp('slow');
    optionsRecherche['page'] = 0;
    // Appel ajax à une requête de type POST pour récupérer le html d'une page de résultat
    updating = $.post(siteURL + "page_results", optionsRecherche, function(data, status) {
        // On rajoute le retour dans la page
        $('#results-produits').fadeOut("normal", function() {
            $(this).html(data).slideDown('normal', function() {
            	nombre_pages_max = $('#nombre_pages').html();

            	$("#button-more-velo").attr('data-numberpage', '1')

            	

            	if (nombre_pages_max <= 1) {
					$("#button-more-velo").addClass("d-none");
				} else {
					$("#button-more-velo").removeClass("d-none");
				}
                // if ($("#nombre_results").html()) {
                //     pluriel = ($('#nombre_results').html() > 1 ? 's' : '');
                //     $("[id^='search_nombre_vehicules_']").each( function() {
                //         $(this).html($('#nombre_results').html() + " offre" + pluriel).show();
                //     });
                //     $("#filtres_mobiles_nb").html(
                //         ($('#nombre_results').html() == 0 ? "Aucun résultat" : ($('#nombre_results').html() > 1 ? "Voir les <b>" + $('#nombre_results').html() + "</b> voitures" : "Voir la voiture"))
                //         );
                // }
                // updatePagination(optionsRecherche['page']);
                // updateURL(optionsRecherche);
                // updateSEO(optionsRecherche);
                $('#results').slideDown();
            });            
        });
    })
    // On renvoie la variable optionsRecherche mise à jour
    return optionsRecherche;
}

function moreDisplayPage(optionsRecherche) {
	if (typeof updating !== 'undefined') { updating.abort(); }

	updating = $.post(siteURL + "page_results", optionsRecherche, function(data, status) {
		$('#results-produits').append(data).find(".produit-results:last").hide().slideDown("normal");
	});

	nombre_pages_max = $('#nombre_pages').html();



	if ((parseInt(optionsRecherche['page']) + 1) >= nombre_pages_max) {
		$("#button-more-velo").addClass("d-none");
	} else {
		$("#button-more-velo").attr("data-numberpage", parseInt(optionsRecherche['page'])+1);
	}

	return optionsRecherche;
}

// Mets à jour l'url du navigateur
function updateURL(optionsRecherche) {
    // menuRecherche = optionsRecherche;
    // Supprime le "/" final de l'url courante
    if (window.location.href.endsWith("/")) { new_url = window.location.href.substring(0, window.location.href.length - 1); } else { new_url = window.location.href; }
    
    // On supprime toutes les valeurs passées en url précédement
    new_url = new_url.replace("/recherche-simplifiee","").replace(/(\?options=\[)(.*)(\])/,"").replace(/(\/achat-vehicules-)([a-z-0-9\-])+/,'').replace("#", "").replace(/(\/en-)([a-z-0-9\-])+/, "").replace(/(\/page)\/(\d)+/, "").replace(/(\/marque)([a-z-0-9])+/, "").replace(/(\/categorie)([a-z-0-9])+/, "");
    // On initialise plusieurs valeurs vides qu'on remplacera plus tart
    new_marque = new_carrosserie = '';

    // console.log(new_carrosserie);

    if (window.location.search != "") {
        search_string = window.location.search;
        string = search_string.replace("?options=[","").replace("]","");
    }
    else {
        search_string = string = "";
    }

    // Si on est dans le cas d'une mise à jour dynamique
    if (typeof optionsRecherche !== "undefined") {
        string = "";
        // console.log(optionsRecherche['marque'].match(/,/g));
        for (option in optionsRecherche) {
            if (option != 'page'
                && ( option != 'marque' || (optionsRecherche['marque'].match(/,/g) != null && (optionsRecherche['marque'].match(/,/g)).length >= 1 ))
                && ( option != 'categorie' || (optionsRecherche['categorie'].match(/,/g) != null && (optionsRecherche['categorie'].match(/,/g)).length >= 1))
                && ( option != 'sortby' || optionsRecherche['sortby'] != "")) {
                string = string + option+':'+optionsRecherche[option]+';';

                // console.log(string);
            }
        }
        if (string != '') {
            search_string = '?options=['+string+']';
        }
        else {
            search_string = "";
        }

        new_marque = "";
        if (typeof optionsRecherche['marque'] !== "undefined" ) {
            marquesRewrite = optionsRecherche['marque'].split(',');
            // console.log(marquesRewrite);
            if (marquesRewrite.length == 1) {

                $(".item-search-marque").each(function(marque) {
            		if ($(this).hasClass("item-search-active")) {
            			new_marque = "/marque-" + $(this).text().replaceAll(" ", "-").toLowerCase();
            		}
            	});
            }
        }

        new_categorie = "";
        if (typeof optionsRecherche['categorie'] !== "undefined" ) {
            categoriesRewrite = optionsRecherche['categorie'].split(',');
            console.log(categoriesRewrite.length);
            if (categoriesRewrite.length == 1) {
            	$(".item-search-categorie").each(function(categorie) {
            		if ($(this).hasClass("item-search-active")) {
            			new_categorie = "/categorie-" + $(this).text().replaceAll(" ", "-").normalize("NFD").replaceAll(/[\u0300-\u036f]/g, "").toLowerCase();
            		}
            	});
                // if ( $("#search_marque > option[value=marque_" + marquesRewrite[0] + "]").length > 0) {
                //     new_marque = "/marque-" + $("#search_marque > option[value=marque_" + marquesRewrite[0] + "]").html().replace(" ", "-").toLowerCase();
                // }
            }
        }
        // console.log(new_categorie);

        /*if (typeof optionsRecherche['carrosserie'] !== "undefined" ) {
            carrosserie_rewrite = $("#search_carrosserie > option[value=\"" + optionsRecherche['carrosserie'] + "\"]").val().split("&nbsp;")[0].toLowerCase().replace(/ /g,"-");
            new_carrosserie = "/carrosserie-" + carrosserie_rewrite;
        }*/
        // updateNavbar(optionsRecherche);
    }
    // Si l'on provient d'une recherche de la page d'accueil ou d'une url rewritée
    else {

        if ($('#fromsearch_marque').length > 0 ) {
            //if ($('#fromsearch_marque').html().split(',').filter(item => item).length == 1) {
            if ($('#fromsearch_marque').html().split(',').length == 1) {
                new_marque = "/marque-" + $("#search_marque > option[value=marque_" + $('#fromsearch_marque').html().replace(',','') + "]").html().replace(' ', '-').toLowerCase();
            }
        }
        else {
            new_marque = "";
        }

        if ($('#fromsearch_categorie').length > 0 ) {
            // if ($("#search_carrosserie > option[value=\"" +  $('#fromsearch_carrosserie').html() + "\"]").length > 0 ) {
        	if ($('#fromsearch_categorie').html().split(',').length == 1) {
                carrosserie_rewrite = $("#search_carrosserie > option[value=" +  $('#fromsearch_carrosserie').html() + "]").val().split("&nbsp;")[0].toLowerCase().replace(/ /g,"-");
                new_carrosserie = "/carrosserie-" + carrosserie_rewrite;
            }
            else {
                new_carrosserie = "";
            }
        }
        else {
            new_carrosserie = "";
        }
    }

    if ($('#produits').length > 0  && $('#produit_search').length > 0) {
        new_url = new_url.replace(/(\/page)\/(\d)+/, "");
        // Cas avec pagination
        // console.log(new_url);
        // if (typeof optionsRecherche !== 'undefined' && optionsRecherche['page'] != 1) {
        //     window.history.pushState('page-'+$('#current_page').html(), '', new_url+new_categorie+new_marque+search_string);
        // }
        // Cas sans pagination
        // else {
        window.history.pushState('page-'+$('#current_page').html(), '', new_url+new_categorie+new_marque+search_string);
        // }
    }

}

function updateSEO(optionsRecherche) {
	form = new FormData;

	form.append("options", optionsRecherche);

	$.post(siteURL + "search_seo_results", optionsRecherche, function(data, status) {
		// console.log(data);
		seo_results = JSON.parse(data);

		document.title = seo_results['title'];
		document.querySelector("meta[name='description']").setAttribute("content", seo_results['metaDescription']);

		$("h1").html(seo_results['baliseH1']);

		text_presentation = "";
		if (seo_results['tagline1'] != "") {
			text_presentation += "<h2>"+ seo_results['tagline1'] +"</h2>";
		}
		if (seo_results['descriptionCourte'] != "") {
			text_presentation += "<p>"+ seo_results['descriptionCourte'] +"</p>";
		}
		if (seo_results['tagline2'] != "") {
			text_presentation += "<h2>"+ seo_results['tagline2'] +"</h2>";
		}

		$("#text-presentation").html(text_presentation);

		// console.log(seo_results['tagline1']);
		// console.log(seo_results['descriptionCourte']);
		// console.log(seo_results['tagline2']);
	});

	// $.ajax({
 //      type: 'POST',
 //      url: siteURL + "search_seo_results",
 //      data: form,
 //      cache: false,
 //      contentType: false,
 //      processData: false,
 //      success: function(data) {
 //      	// datetimepicker.setOptions({
 //      	// 	allowTimes: data.split(",")
 //      	// });
 //      },
 //    });
}