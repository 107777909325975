$(document).ready(function() {

	if ($('#splide-slideshow').length > 0) {
		new Splide( '#splide-slideshow', {
			type   : 'loop',
			perPage: 1,
			autoplay: true,
			arrows: true,
			interval: 15000,
			pagination: false,
		} ).mount();
	}

	if ($('#splide-article-actualites').length > 0) {
		new Splide( '#splide-article-actualites', {
			type   : 'loop',
			perPage: 3,
			breakpoints: {
				991.98: {
					perPage: 2,
				},
				767.98: {
					perPage: 1,
				},

			},
			autoplay: true,
			arrows: false,
			interval: 17000,
		} ).mount();
	}
});